
export default {
    emits: [
        'done',
        'update:visible'
    ],
    props: {
        // 弹窗是否打开
        visible: Boolean,
        // 分组ID
        groupid: Number,
        // 修改回显的数据
        data: Object
    },
    data() {
        return {
            // 表单数据
            form: Object.assign({}, this.data),
            // 表单验证规则
            rules: {
                ship_method_id: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'string',
                    trigger: 'blur'
                }],
                script_type: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'string',
                    trigger: 'blur'
                }]
            },
            // 提交状态
            loading: false,
            // 是否是修改
            isUpdate: false,
            // 物流渠道列表
            ship_methods: []
        };
    },
    watch: {
        data() {
            if (this.data) {
                this.form = Object.assign({}, this.data);
                this.form.ship_method_id = this.form.ship_method_id.toString();
                this.isUpdate = true;
            } else {
                this.form = {};
                this.isUpdate = false;
            }
            if (this.$refs.form) {
                this.$refs.form.clearValidate();
            }
        }
    },
    mounted() {
        this.load_ship_methods();
    },
    methods: {
        load_ship_methods() {
            this.$http.get('/admin/ship_method/simple_list').then(res => {
                if (res.data.code === 0) {
                    this.ship_methods = res.data.data;
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
        },
        /* 更新visible */
        updateVisible(value) {
            this.$emit('update:visible', value);
        },
        /* 保存编辑 */
        save() {
            this.$refs.form.validate().then(() => {
                this.loading = true;
                this.$http.post(`/admin/charge_group_script/update/${this.groupid}`, Object.assign({}, this.form)).then(res => {
                    this.loading = false;
                    if (res.data.code === 0) {
                        this.$message.success(res.data.msg);
                        if (!this.isUpdate) {
                            this.form = {};
                        }
                        this.updateVisible(false);
                        this.$emit('done');
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                });
            }).catch(() => {});
        },
        onClose() {
            this.updateVisible(false);
        }
    }
}
